.Navink {		
	color:rgba(255, 255, 255, 0.76);
    text-decoration: none;
}

.certificado {
	display: flex;
	align-items: center;
	justify-content: center;
}
