
.containerasociado {
	margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: sans-serif;
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 90vh;
	background: #0F2027;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #2C5364, #203A43, #0F2027);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #2C5364, #203A43, #0F2027); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.Containera{
	color: chartreuse
}